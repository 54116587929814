import React from "react";

const Version = () => {
  return (
    <div className="copy-right bg2 p-3 text-center br10">
      © 2025 Dynamic Tech. All Rights Reserved <br />{" "}
      <small>Version 1.1.5</small>
    </div>
  );
};

export default Version;
