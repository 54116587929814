import {
  cryptoTradeHistory,
  cryptoTransHistory,
  equityTradeHistory,
  forexTradeHistory,
  cryptoTradeMarginHistory,cryptoTradeFuturesHistory,
} from "../components/constant/Api";
import { myApi } from "./api";

export const TradeApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getCryptoTradeHistory: builder.query({
      query: () => ({
        url: cryptoTradeHistory,
        method: "POST",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? "" : "";
      },
    }),
    getCryptoTransactionHistory: builder.query({
      query: () => ({
        url: cryptoTransHistory,
        method: "POST",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? "" : "";
      },
    }),
    getForexTradeHistory: builder.query({
      query: () => ({
        url: forexTradeHistory,
        method: "POST",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response ?? "" : "";
      },
    }),
    getEquityTradeHistory: builder.query({
      query: () => ({
        url: equityTradeHistory,
        method: "POST",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response ?? "" : "";
      },
    }),
    getCryptoTradeMarginHistory: builder.query({
      query: () => ({
        url: cryptoTradeMarginHistory,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response ?? "" : "";
      },
    }),
    getCryptoFutureTradeHistory: builder.query({
      query: () => ({
        url: cryptoTradeFuturesHistory,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response ?? "" : "";
      },
    }),
  }),
});
export const {
  useGetCryptoTradeHistoryQuery,
  useGetCryptoTradeMarginHistoryQuery,
  useGetCryptoTransactionHistoryQuery,
  useGetForexTradeHistoryQuery,
  useGetEquityTradeHistoryQuery,
  useGetCryptoFutureTradeHistoryQuery,
} = TradeApi;
