import React, { useState, useEffect } from "react";
import Navbar from "../widgets/Navbar";
import Footer from "../widgets/Footer";
import Sidebar from "../widgets/Sidebar";
import { useTranslation } from "react-i18next";
import {
  useCreateMasterProfileMutation,
  useGetMasterProfileQuery,
  useUpdateMasterProfileImageMutation,
} from "../../redux/userApi";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { imageUrl } from "../constant/BaseUrl";

const MasterProfileSetup = () => {
  const { t } = useTranslation();
  const { userId } = useSelector((state) => state.user);
  const [createMasterProfile, { isLoading }] = useCreateMasterProfileMutation();
  const [updateMasterImage, { isLoading: updatingImage }] =
    useUpdateMasterProfileImageMutation();
  const { data, refetch } = useGetMasterProfileQuery({ masterUserId: userId });

  console.log(userId, " hiiiiisdfjksljfksjflsd", data);

  // States for form fields
  const [nickname, setNickname] = useState("");
  const [segments, setSegments] = useState({
    crypto: false,
    equity: false,
    forex: false,
  });
  const [description, setDescription] = useState("");
  const [commissionRate, setCommission] = useState(0);
  const [minAmount, setMinAmount] = useState("");
  const [links, setLinks] = useState("");
  const [tags, setTags] = useState("");
  const [errors, setErrors] = useState({});
  const [profileImage, setProfileImage] = useState(null);
  const [profileImagePreview, setProfileImagePreview] = useState(null);

  useEffect(() => {
    if (data?.profileImage) {
      setProfileImagePreview(data.profileImage); // Assuming `profileImage` field in API response
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      setNickname(data.nickName || "");
      setDescription(data.description || "");
      setTags(data.tags || "");
      setLinks(data.links || "");
      setCommission(data.commissionRate || 0);
      setMinAmount(data.minimumInvestment || "");
      setProfileImage(data.profileImage || profileImagePreview || "");
      setSegments({
        crypto: data.is_crypto === "1",
        equity: data.is_equity === "1",
        forex: data.is_forex === "1",
      });
    }
  }, [data]);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfileImage(file);
      setProfileImagePreview(URL.createObjectURL(file)); // Preview the selected image

      const formData = new FormData();
      formData.append("masterProfileImage", file);

      try {
        const response = await updateMasterImage(formData).unwrap();
        if (response?.status) {
          toast.dismiss();
          toast.success(t("Profile image updated successfully!"));
          refetch();
        } else {
          toast.dismiss();
          toast.error(t("Failed to update profile image."));
        }
      } catch (error) {
        toast.dismiss();
        toast.error(t("An error occurred while updating profile image."));
        console.error("Profile image update error:", error);
      }
    }
  };

  const triggerFileInput = () => {
    document.getElementById("profileFileInput").click();
  };

  // Validation functions
  const validateNickname = (value) => {
    if (!value.trim()) {
      setErrors((prev) => ({ ...prev, nickname: "Nick Name is required." }));
    } else {
      setErrors((prev) => {
        const { nickname, ...rest } = prev;
        return rest;
      });
    }
  };

  const validateSegments = () => {
    if (!Object.values(segments).some((value) => value)) {
      setErrors((prev) => ({
        ...prev,
        segments: "At least one segment must be selected.",
      }));
    } else {
      setErrors((prev) => {
        const { segments, ...rest } = prev;
        return rest;
      });
    }
  };

  const validateCommission = (value) => {
    if (value === 0) {
      setErrors((prev) => ({
        ...prev,
        commissionRate: "Commission must be greater than 0.",
      }));
    } else {
      setErrors((prev) => {
        const { commissionRate, ...rest } = prev;
        return rest;
      });
    }
  };

  const handleNicknameChange = (value) => {
    setNickname(value);
    validateNickname(value);
  };

  const handleSegmentChange = (segment) => {
    setSegments((prev) => {
      const updatedSegments = { ...prev, [segment]: !prev[segment] };

      const isAnySelected = Object.values(updatedSegments).some(
        (value) => value
      );

      setErrors((prevErrors) => {
        if (!isAnySelected) {
          return {
            ...prevErrors,
            segments: "At least one segment must be selected.",
          };
        } else {
          const { segments, ...rest } = prevErrors;
          return rest;
        }
      });

      return updatedSegments;
    });
  };

  const handleCommissionChange = (value) => {
    setCommission(value);
    validateCommission(value);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform final validation
    validateNickname(nickname);
    validateSegments();
    validateCommission(commissionRate);

    if (Object.keys(errors).length === 0) {
      const formData = {
        nickname,
        segments,
        description,
        commissionRate,
        minAmount,
        links,
        tags,
      };
      console.log("Form Data:", formData);

      const { data } = await createMasterProfile(formData);
      console.log(data, "reusssls ");

      if (data.status) {
        toast.dismiss();
        toast.success(t(data.message));
        refetch();
      } else {
        toast.dismiss();
        toast.error(t(data.message));
      }
    }
  };

  return (
    <div className="p-3 bg2 br20 mb-4">
      <h5 className="mb-3">Master Profile Setup</h5>

      {/* Profile Section */}
      {data && (
        <div className="mb-4 d-flex align-items-center">
          <div>
            {profileImage ? (
              <img
                src={imageUrl + "/static/masterProfileImages/" + profileImage}
                alt="Profile"
                className="rounded-circle"
                width="50"
                height="50"
              />
            ) : (
              <img
                className="head_logo logo_lite"
                src="/img/Default_pfp.png"
                alt="User Profile default"
              />
            )}
          </div>
          <div className="ml-2 cursor-pointer">
            {/* <h6 className="mb-0">Your Profile</h6> */}

            <input
              type="file"
              id="profileFileInput"
              name="masterProfileImage"
              className="logo_dark"
              onChange={handleFileChange}
              accept="image/*"
            />

            <span
              type="button"
              className="btn4 btn-link4 p-0"
              onClick={triggerFileInput}
            >
              <i class="bi bi-pencil"></i>
            </span>
          </div>
        </div>
      )}

      <form noValidate="" onSubmit={handleSubmit}>
        <input type="hidden" name="type" defaultValue="bank" />
        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="nickname">Nick Name*</label>
            <input
              type="text"
              className="form-control"
              id="nickname"
              name="nickname"
              placeholder="Enter nick name"
              value={nickname}
              onChange={(e) => handleNicknameChange(e.target.value)}
              required
            />
            {errors.nickname && (
              <small className="text-danger">{errors.nickname}</small>
            )}
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="segment">Select Segment*</label>
            <div className="orderforms-inputs d-flex mt-2 justify-content-between">
              {["crypto", "equity", "forex"].map((segment) => (
                <div className="form-check mb-2" key={segment}>
                  <input
                    className="form-check-input check_box"
                    type="checkbox"
                    id={`segment-${segment}`}
                    checked={segments[segment]}
                    onChange={() => handleSegmentChange(segment)}
                  />
                  <span className="checkmark"></span>
                  <label
                    className="form-check-label pl-2"
                    htmlFor={`segment-${segment}`}
                  >
                    {segment.charAt(0).toUpperCase() + segment.slice(1)}
                  </label>
                </div>
              ))}
            </div>
            {errors.segments && (
              <small className="text-danger">{errors.segments}</small>
            )}
          </div>
          <div className="col-md-12 mb-3">
            <label htmlFor="description">Description</label>
            <textarea
              type="text"
              className="form-control"
              id="description"
              name="description"
              placeholder="Enter description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="commission">Set Commission*</label>
            <input
              type="range"
              className="w100 mt-2"
              step="1"
              min="0"
              max="50"
              value={commissionRate}
              onChange={(e) => handleCommissionChange(parseInt(e.target.value))}
            />
            <div>{commissionRate}%</div>
            {errors.commissionRate && (
              <small className="text-danger">{errors.commissionRate}</small>
            )}
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="minAmount">Minimum Investment Amount</label>
            <input
              type="number"
              className="form-control"
              placeholder="Min Amount"
              value={minAmount}
              onChange={(e) => setMinAmount(e.target.value)}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="links">Links</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter links"
              value={links}
              onChange={(e) => setLinks(e.target.value)}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="tags">Tags</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Tags"
              value={tags}
              onChange={(e) => setTags(e.target.value)}
            />
          </div>
          <div className="col-md-6 mt-md-4">
            <button type="submit" className="btn btn-primary pl-4 pr-4">
              {isLoading ? "Submitting..." : data ? "Update Profile" : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default MasterProfileSetup;
