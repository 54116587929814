import { myApi } from "./api";
import {
  cancelOrderCryptoFutures,
  cancleOrder,
  cancleOrderCrypto,
  closeOrderCrypto,
  closeOrderCryptoFutures,
  createCryptoFuturesOrder,
  createOrder,
  createOrderClickCrypto,
  getActiveTabs,
  modifyOrderClickCryptoMargin,
  pairInfo,
  partialCloseOrderCrypto,
  partialCloseOrderCryptoFutures,
} from "../components/constant/Api";

export const ExchangeApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getSinglePairInfo: builder.mutation({
      query: (post) => ({
        url: pairInfo,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    getAllActiveTabs: builder.query({
      query: () => ({
        url: getActiveTabs,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? "" : "";
      },
    }),
    placeOrder: builder.mutation({
      query: (post) => ({
        url: createOrder,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.success ? response ?? response : response;
      },
    }),
    cancleOrder: builder.mutation({
      query: (post) => ({
        url: cancleOrder,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.success ? response ?? response : response;
      },
    }),
    createOrderClickCrypto: builder.mutation({
      query: (post) => ({
        url: createOrderClickCrypto,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.success ? response ?? response : response;
      },
    }),
    closeOrderCrypto: builder.mutation({
      query: (post) => ({
        url: closeOrderCrypto,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.success ? response ?? response : response;
      },
    }),
    partialCloseOrderCrypto: builder.mutation({
      query: (post) => ({
        url: partialCloseOrderCrypto,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.success ? response ?? response : response;
      },
    }),
    cancleOrderCrypto: builder.mutation({
      query: (post) => ({
        url: cancleOrderCrypto,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.success ? response ?? response : response;
      },
    }),
    modifyOrderClickCrypto: builder.mutation({
      query: (post) => ({
        url: modifyOrderClickCryptoMargin,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.success ? response ?? response : response;
      },
    }),
    createCryptoFuturesOrder: builder.mutation({
      query: (post) => ({
        url: createCryptoFuturesOrder,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.success ? response ?? response : response;
      },
    }),
    closeOrderCryptoFuture: builder.mutation({
      query: (post) => ({
        url: closeOrderCryptoFutures,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.success ? response ?? response : response;
      },
    }),
    partialCloseOrderCryptoFutures: builder.mutation({
      query: (post) => ({
        url: partialCloseOrderCryptoFutures,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.success ? response ?? response : response;
      },
    }),
    cancelOrderCryptoFuture: builder.mutation({
      query: (post) => ({
        url: cancelOrderCryptoFutures,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.success ? response ?? response : response;
      },
    }),
  }),
});

export const {
  useGetSinglePairInfoMutation,
  useGetAllActiveTabsQuery,
  usePlaceOrderMutation,
  useCancleOrderMutation,
  useCreateOrderClickCryptoMutation,
  useCloseOrderCryptoMutation,
  useCancleOrderCryptoMutation,
  useModifyOrderClickCryptoMutation,
  usePartialCloseOrderCryptoMutation,
  useCreateCryptoFuturesOrderMutation,
  useCloseOrderCryptoFutureMutation,
  usePartialCloseOrderCryptoFuturesMutation,
  useCancelOrderCryptoFutureMutation
} = ExchangeApi;
