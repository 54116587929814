import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import toast from "react-hot-toast";
import { useGetCryptoPortfolioQuery } from "../../redux/userApi";
import { useDropdown } from "./useDropdown";
import { useGetUserForexPortfolioMutation } from "../../redux/forexApi";
import { Helmet } from "react-helmet";
import { myApi } from "../../redux/api";
import { useDispatch } from "react-redux";
import GoogleTranslator from "./GoogleTranslator";
import ChangeLang from "./ChangeLang";
import { useTranslation } from "react-i18next";
import { formatDecimal } from "../../util/decimalFormatter";
import { Modal, Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";

const Navbar = () => {
  const userSegment = useSelector((state) => state.user);
  const token = localStorage.getItem("jwtToken");
  const { t } = useTranslation();
  const pathname = window.location.pathname;
  const firstPath = pathname.split("/")[1];
  const { symbol } = useParams();
  // const scriptAdded = useRef(false);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const themeDropdown = useDropdown();
  const menuDropdown = useDropdown();
  const [userPortfolioForex] = useGetUserForexPortfolioMutation();
  const { data: cryptoPortfolio, isLoading } = useGetCryptoPortfolioQuery(
    undefined,
    { skip: !token }
  );
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [selectedTheam, setSelectedTheam] = useState("");
  const [floatingProfits, setFloatingProfits] = useState(0.0);
  const [equityPortfolio, setEquityPortfolio] = useState(0.0);
  const [freeBalance, setFreeBalance] = useState(0.0);
  const [usedBalance, setUsedBalance] = useState(0.0);
  const [totalBalance, setTotalBalance] = useState(0.0);

  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    if (token) {
      // getForexPortfolio();
      getEquityPortfolio();
    }
    // if (!scriptAdded.current) {
    //   const addScript = document.createElement("script");
    //   addScript.setAttribute(
    //     "src",
    //     "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    //   );
    //   document.body.appendChild(addScript);

    //   window.googleTranslateElementInit = () => {
    //     new window.google.translate.TranslateElement(
    //       {
    //         pageLanguage: "en", // Change 'en' to your site's original language
    //         layout:
    //           window.google.translate.TranslateElement.InlineLayout.SIMPLE,
    //       },
    //       "google_translate_element"
    //     );
    //   };
    // }
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getForexPortfolio = async () => {
    const data = {
      symbolType: "forex",
    };
    const res = await userPortfolioForex(data);
    const resp = res.data;
    if (resp?.status === true) {
      setFloatingProfits(resp.data);
    }
  };

  const getEquityPortfolio = async () => {
    const data = {
      symbolType: "equity",
    };
    const res = await userPortfolioForex(data);
    const resp = res.data;
    if (resp?.status === true) {
      setFreeBalance(resp.data.freeBalance ? resp.data.freeBalance : "0.0");
      setUsedBalance(resp.data.usedMargin ? resp.data.usedMargin : "0.0");
      setTotalBalance(resp.data.balance ? resp.data.balance : "0.0");
    }
  };

  const logoutPage = () => {
    dispatch(myApi.util.resetApiState());
    toast.success(t("Logout successfully"));
    localStorage.clear();
    navigate("/login");
    logout();
  };

  useEffect(() => {
    const theme = localStorage.getItem("theme");
    if (theme) {
      document.body.className = theme;
    }
  }, []);

  useEffect(() => {
    const theme = localStorage.getItem("theme");
    if (theme) {
      document.body.className = theme;
      setSelectedTheam(theme);
    }
  }, []);

  const setTheme = (e, themeName) => {
    e.preventDefault();
    if (themeName === "defaultTheme") {
      document.body.className = "";
    } else {
      document.body.className = themeName;
    }
    setSelectedTheam(themeName);
    localStorage.setItem("theme", themeName);
  };

  return (
    <div className="header_main bg2">
      <div className="container">
        <div className="manu d-flex align-items-center">
          <Link to="/">
            <img
              className="head_logo logo_lite"
              src="/img/logo.png"
              alt="Head Logo"
            />
            <img
              src="/img/logo-black.png"
              alt="Head Logo"
              className="head_logo logo_dark"
            />
          </Link>
          <span className="manu_btn" onClick={handleShow}>
            <i className="bi bi-list"></i>
          </span>

          {!token && (
            <div className="exchange_route ml-4 d-flex mr-auto">
              <NavLink
                exact={true}
                to="/crypto/BTC/USDT"
                activeClassName="active"
              >
                {t("Crypto")}
                <br />
                <small style={{ textTransform: "capitalize" }}>
                  {t("Click here to view platform")}
                </small>
              </NavLink>

              <NavLink
                exact={true}
                to={`/forex/${
                  firstPath === "forex"
                    ? symbol
                      ? symbol
                      : "EURUSD"
                    : "EURUSD"
                }`}
                activeClassName="active"
              >
                {t("FX")}
                <br />
                <small style={{ textTransform: "capitalize" }}>
                  {t("Click here to view platform")}
                </small>
              </NavLink>

              <NavLink
                exact={true}
                to={`/forex-equity/${
                  firstPath === "forex-equity"
                    ? symbol
                      ? symbol
                      : "GOOGL"
                    : "GOOGL"
                }`}
                activeClassName="active"
              >
                {t("Equity")}
                <br />
                <small style={{ textTransform: "capitalize" }}>
                  {t("Click here to view platform")}
                </small>
              </NavLink>
            </div>
          )}
          <div className="head_menu_div ml-auto  ">
            <ul
              id="menu-header-menu"
              className=" ml-auto align-items-center d-flex"
            ></ul>
          </div>
          {/* {token && (
            <div className="row current_unds_item">
              <div className="mr-3">
                <p className=" mb-0">
                  {t("Total")}: $
                  {isNaN(totalBalance)
                    ? "0.00"
                    : formatDecimal(totalBalance, 2)}
                </p>
              </div>
              <div className="mr-3">
                <p className="mb-0">
                  {t("Used")}: $
                  {isNaN(usedBalance) ? "0.00" : formatDecimal(usedBalance, 2)}
                </p>
              </div>
              <div className="mr-5">
                <p className="mb-0">
                  {t("Free")}: $
                  {isNaN(freeBalance) ? "0.00" : formatDecimal(freeBalance, 2)}
                </p>
              </div>
            </div>
          )} */}

          <ChangeLang />

          {!token && (
            <>
              <Link className="btn ml-3 mo_none" to={"/login"}>
                {t("Login")}
              </Link>

              <Link className="btn ml-3 mo_none" to={"/register"}>
                {t("Signup")}
              </Link>
            </>
          )}

          <div
            className="dropdown user_dropdown mr-3 mo_none"
            style={{ left: "20px" }}
          >
            <span
              type="button"
              className="dropdown-toggle color-toggle mr-3"
              data-toggle="dropdown"
              onClick={() => themeDropdown.setIsOpen(!themeDropdown.isOpen)}
            >
              {selectedTheam === "lightTheme" ? (
                <i className="bi bi-brightness-high"></i>
              ) : selectedTheam === "defaultTheme" ? (
                <i className="bi bi-moon-stars"></i>
              ) : (
                <i className="bi bi-moon"></i>
              )}{" "}
            </span>
            <div
              ref={themeDropdown.ref}
              className={
                themeDropdown.isOpen
                  ? "dropdown-menu2 active"
                  : "dropdown-menu2"
              }
            >
              <a
                className="a_flex"
                href="#!"
                onClick={(e) => setTheme(e, "lightTheme")}
              >
                <i className="bi bi-brightness-high mr-1"></i> {t("Light")}
              </a>
              <a
                className="a_flex"
                href="#!"
                onClick={(e) => setTheme(e, "defaultTheme")}
              >
                <i className="bi bi-moon-stars mr-1"></i> {t("Dim")}
              </a>
              <a
                className="a_flex"
                href="#!"
                onClick={(e) => setTheme(e, "darkTheme")}
              >
                <i className="bi bi-moon mr-1"></i> {t("Dark")}
              </a>
            </div>
          </div>

          {token && (
            <div className="dropdown user_dropdown mo_none">
              <span
                type="button"
                className=" dropdown-toggle"
                data-toggle="dropdown"
                onClick={() => menuDropdown.setIsOpen(!menuDropdown.isOpen)}
              >
                <i className="bi bi-person"></i>{" "}
              </span>
              <div
                ref={menuDropdown.ref}
                className={
                  menuDropdown.isOpen
                    ? "dropdown-menu2 active"
                    : "dropdown-menu2"
                }
              >
                <Link className="a_flex" to="/dashboard">
                  <i className="bi bi-grid mr-2"></i>
                  {t("Dashboard")}
                </Link>
                <Link className="a_flex" to={""} onClick={logoutPage}>
                  <i className="bi bi-box-arrow-right mr-2"></i>
                  {t("Logout")}
                </Link>
              </div>
            </div>
          )}
        </div>
        {/* React-Bootstrap Modal (Styled as Sidebar) */}
        <Modal
          show={show}
          onHide={() => setShow(false)}
          className="custom-modal custom-modal-nav"
        >
          <Modal.Body>
            <span
              className="close-btn close_btn"
              onClick={() => setShow(false)}
            >
              <i className="bi bi-x-lg"></i>
            </span>

            <Link to="/" className="mb-3 d-block">
              <img
                className="head_logo logo_lite"
                src="/img/logo.png"
                alt="Head Logo"
              />
              <img
                src="/img/logo-black.png"
                alt="Head Logo"
                className="head_logo logo_dark"
              />
            </Link>
            <div className="modal-header-buttons d-flex">
              {!token && (
                <>
                  <Button
                    variant="dark"
                    className="btn-login w100 mr-2 "
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    Log In
                  </Button>
                  <Button
                    variant="warning"
                    className="ml-2 btn-signup w100"
                    onClick={() => {
                      navigate("/register");
                    }}
                  >
                    Sign Up
                  </Button>
                </>
              )}
            </div>
            {/* Menu Items */}
            <ul className="modal-menu">
              <li>
                <i className="bi bi-grid mr-2"></i>
                <NavLink exact={true} to="/dashboard" activeClassName="active">
                  {t("Dashboard")}
                </NavLink>
              </li>

              {userSegment?.isCryptoEnabled === "yes" && (
                <li>
                  <i className="bi bi-currency-bitcoin mr-2"></i>{" "}
                  <NavLink
                    exact={true}
                    to="/crypto/BTC/USDT"
                    activeClassName="active"
                  >
                    {t("Crypto")}
                  </NavLink>
                </li>
              )}
              {userSegment?.isFxEnabled === "yes" && (
                <li>
                  <i className="bi bi-bar-chart mr-2"></i>{" "}
                  <NavLink
                    exact={true}
                    to={`/forex/${
                      firstPath === "forex"
                        ? symbol
                          ? symbol
                          : "EURUSD"
                        : "EURUSD"
                    }`}
                    activeClassName="active"
                  >
                    {t("FX")}
                  </NavLink>
                </li>
              )}
              {userSegment?.isEquityEnabled === "yes" && (
                <li>
                  <i className="bi bi-graph-up mr-2"></i>{" "}
                  <NavLink
                    exact={true}
                    to={`/forex-equity/${
                      firstPath === "forex-equity"
                        ? symbol
                          ? symbol
                          : "GOOGL"
                        : "GOOGL"
                    }`}
                    activeClassName="active"
                  >
                    {t("Equity")}
                  </NavLink>
                </li>
              )}
              {/* <li>
                <i className="bi bi-wallet2 mr-2"></i> Futures
              </li>
              <li>
                <i className="bi bi-trophy mr-2"></i> Earn
              </li>
              <li>
                <i className="bi bi-collection mr-2"></i> Square
              </li> */}

              <li className="align-items-center d-flex">
                <span
                  type="button"
                  className="dropdown-toggle color-toggle mr-2"
                  data-toggle="dropdown"
                  onClick={() => themeDropdown.setIsOpen(!themeDropdown.isOpen)}
                >
                  {selectedTheam === "lightTheme" ? (
                    <i className="bi bi-brightness-high"></i>
                  ) : selectedTheam === "defaultTheme" ? (
                    <i className="bi bi-moon-stars"></i>
                  ) : (
                    <i className="bi bi-moon"></i>
                  )}{" "}
                </span>
                Theme
                <div className="ml-auto mo_color">
                  <a
                    className={
                      selectedTheam === "lightTheme"
                        ? "a_flex active"
                        : "a_flex"
                    }
                    href="#!"
                    onClick={(e) => setTheme(e, "lightTheme")}
                  >
                    <i className="bi bi-brightness-high mr-1"></i> {t("Light")}
                  </a>
                  <a
                    className={
                      selectedTheam === "defaultTheme"
                        ? "a_flex active"
                        : "a_flex"
                    }
                    href="#!"
                    onClick={(e) => setTheme(e, "defaultTheme")}
                  >
                    <i className="bi bi-moon-stars mr-1"></i> {t("Dim")}
                  </a>
                  <a
                    className={
                      selectedTheam === "darkTheme" ? "a_flex active" : "a_flex"
                    }
                    href="#!"
                    onClick={(e) => setTheme(e, "darkTheme")}
                  >
                    <i className="bi bi-moon mr-1"></i> {t("Dark")}
                  </a>
                </div>
              </li>
              <li>
                <Link className="a_flex" to={""} onClick={logoutPage}>
                  <i className="bi bi-box-arrow-right mr-2"></i>
                  {t("Logout")}
                </Link>
              </li>
            </ul>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default Navbar;
