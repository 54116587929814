import React, { useEffect } from "react";
import Footer from "../widgets/Footer";
import Navbar from "../widgets/Navbar";
import Sidebar from "../widgets/Sidebar";
import { ForgotValid } from "../validations/ForgotValid";
import {
  useChangePasswordMutation,
  useGetSubsMastersHistoryQuery,
} from "../../redux/userApi";
import toast from "react-hot-toast";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { imageUrl } from "../constant/BaseUrl";
// import { getMasters } from "../services/Login";
import { useGetSubsMastersQuery } from "../../redux/userApi";
import { useSelector } from "react-redux";

const CopierMasters = () => {
  const { t } = useTranslation();
  const { userId } = useSelector((state) => state.user);
  const { data, isLoading, refetch } = useGetSubsMastersQuery(
    { copierUserId: userId },
    { skip: !userId }
  );
  const {
    data: mastersHistoryData,
    isLoading: masterHistoryLoading,
    refetch: refetchHistory,
  } = useGetSubsMastersHistoryQuery(
    { copierUserId: userId },
    { skip: !userId }
  );
  useEffect(() => {
    if (userId) {
      refetch();
      refetchHistory()
    }
  }, []);


  if (isLoading) return;

  if (masterHistoryLoading) return;

  return (
    <div>
      <Navbar />
      <section className="p70 pt-md-5 pb-md-5">
        <div className="container ">
          <div className="row mt-4">
            <Sidebar />
            <div className="col-xl-9 col-lg-8 bg2 p-3 bg2 br20 mb-4">
              <div className="h-50">
                <h4 className="mb-0">
                  Masters you copy {data && `(${data.length})`}
                </h4>
                <div className="row">
                  {data
                    ? data.map((item) => (
                        <div
                          className="col-md-6 col-sm-6 col-lg-4 mt-4"
                          key={item.masterUserId}
                        >
                          <Link
                            to={`/copier-area/${userId}/${item.masterUserId}`}
                            // target="_blank"
                            className="rating-card bg2"
                          >
                            <div className="master-profile rating-card-header">
                              <div className="mpa">
                                <img
                                  src={
                                    item.profileImage
                                      ? imageUrl +
                                        "/static/masterProfileImages/" +
                                        item.profileImage
                                      : "img/Default_pfp.png"
                                  }
                                  className="profile_avatar-flag"
                                  alt="profile master"
                                />
                              </div>
                              <div className="master-profile__info">
                                <span className="master-profile__master-nickname text-body-2">
                                  {" "}
                                  {item.nickName}{" "}
                                </span>
                                <div className="small">
                                  <i className="bi bi-star color1 mr-2"></i>{" "}
                                  {item.level}
                                </div>
                              </div>
                            </div>
                            <div className="card-content">
                              <div className="card-content__performance row">
                                <div className="ccpi2 col-md-4 col-xl-5 col-lg-4">
                                  <div className="ccpi">Profit & Loss</div>
                                  <div className="ccpv">{item.pnl}</div>
                                </div>
                                <div className="ccpi2 col-md-4 col-xl-5 col-lg-4">
                                  <div className="ccpi">Floating Profit</div>
                                  <div className="ccpv">
                                    {item.floatingProfit}
                                  </div>
                                </div>
                                <div className="ccpi2 col-md-4 col-xl-5 col-lg-4">
                                  <div className="ccpi">Equity</div>
                                  <div className="ccpv">{item.equity}</div>
                                </div>
                                <div className="ccpi2 col-md-4 col-xl-5 col-lg-4">
                                  <div className="ccpi">Gain</div>
                                  <div className="ccpv succes_s">
                                    {item.gains > 0 ? +item.gains : -item.gains}
                                    %
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))
                    : <div className="p-5">No records found</div>}
                </div>
              </div>
              <hr />
              <div>
                <h4 className="mb-0">
                  Masters you copied(History){" "}
                  {mastersHistoryData && `(${mastersHistoryData.length})`}
                </h4>
                <div className="row">
                  {mastersHistoryData
                    ? mastersHistoryData.map((item) => (
                        <div
                          className="col-md-6 col-sm-6 col-lg-4 mt-4"
                          key={item.masterUserId}
                        >
                          <Link
                            // to={`/copier-area/${userId}/${item.masterUserId}`}
                            to={"#"}
                            // target="_blank"
                            className="rating-card bg2"
                          >
                            <div className="master-profile rating-card-header">
                              <div className="mpa">
                                <img
                                  src={
                                    item.profileImage
                                      ? imageUrl +
                                        "/static/masterProfileImages/" +
                                        item.profileImage
                                      : "img/Default_pfp.png"
                                  }
                                  className="profile_avatar-flag"
                                  alt="profile master"
                                />
                              </div>
                              <div className="master-profile__info">
                                <span className="master-profile__master-nickname text-body-2">
                                  {" "}
                                  {item.nickName}{" "}
                                </span>
                                <div className="small">
                                  <i className="bi bi-star color1 mr-2"></i>{" "}
                                  {item.level}
                                </div>
                              </div>
                            </div>
                            <div className="card-content">
                              <div className="card-content__performance row">
                                <div className="ccpi2 col-md-4 col-xl-5 col-lg-4">
                                  <div className="ccpi">Profit & Loss</div>
                                  <div className="ccpv">{item.pnl}</div>
                                </div>
                                <div className="ccpi2 col-md-4 col-xl-5 col-lg-4">
                                  <div className="ccpi">Floating Profit</div>
                                  <div className="ccpv">
                                    {item.floatingProfit}
                                  </div>
                                </div>
                                <div className="ccpi2 col-md-4 col-xl-5 col-lg-4">
                                  <div className="ccpi">Equity</div>
                                  <div className="ccpv">{item.equity}</div>
                                </div>
                                <div className="ccpi2 col-md-4 col-xl-5 col-lg-4">
                                  <div className="ccpi">Gain</div>
                                  <div className="ccpv succes_s">
                                    {item.gains > 0 ? +item.gains : -item.gains}
                                    %
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))
                    : <div className="ml-3">No records found</div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default CopierMasters;
