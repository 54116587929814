import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

// Register Chart.js modules
ChartJS.register(ArcElement, Tooltip, Legend);
const predefinedColors = [
  "#4285F4", // Blue
  "#F4B400", // Yellow
  "#34A853", // Green
  "#EA4335", // Red
  "#FF6D01", // Orange
  "#46BDC6", // Cyan
  "#A142F4", // Purple
];

const AssetPreferencesChart = ({ allData: { symbols, values } }) => {
  console.log({ symbols, values }, "kk");
  // Sample data for the chart
  const data = {
    labels: symbols,
    datasets: [
      {
        label: "Asset Preferences",
        data: values,
        backgroundColor: predefinedColors,
        hoverOffset: 4,
        borderWidth: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "left",
        labels: {
          color: "#ffffff", // Change text color to white for dark backgrounds
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.label}: ${context.raw}%`;
          },
        },
      },
    },
    cutout: "60%",
  };

  return (
    <>
      {symbols.length > 0 ? (
        <div
          style={{
            // height: "200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Doughnut data={data} options={options} />
        </div>
      ) : (
        <div>Nothing to show</div>
      )}
    </>
  );
};

export default AssetPreferencesChart;
