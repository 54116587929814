import { myApi } from "./api";
import {
  getAllWallets,
  transferAssets,
  paymentToken,
  paymentRequest,
  paymentCountry,
  paymentMethods,
  withdrawalRequest,
  personalDetails,
  accountDetails,
} from "../components/constant/Api";

export const AccountApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllUserWallet: builder.query({
      query: () => ({
        url: getAllWallets,
        method: "POST",
      }),
      transformResponse: (response, meta, arg) => {
        return response.success ? response?.data ?? [] : [];
      },
      providesTags: (_) => [""],
    }),
    transferAccount: builder.mutation({
      query: (post) => ({
        url: transferAssets,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response ?? response : response;
      },
    }),
    paymentRequest: builder.mutation({
      query: (post) => ({
        url: paymentRequest,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response ?? response : response;
      },
    }),
    withdrawalRequest: builder.mutation({
      query: (post) => ({
        url: withdrawalRequest,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response ?? response : response;
      },
    }),
    paymentMethods: builder.mutation({
      query: (post) => ({
        url: paymentMethods,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response ?? response : response;
      },
    }),
    getPaymentToken: builder.query({
      query: () => ({
        url: paymentToken,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? "" : "";
      },
    }),
    paymentCountry: builder.query({
      query: () => ({
        url: paymentCountry,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? "" : "";
      },
    }),
    getAccountDetails: builder.query({
      query: () => ({
        url: accountDetails,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? "" : "";
      },
    }),
    accountDetails: builder.mutation({
      query: (post) => ({
        url: accountDetails,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response ?? response : response;
      },
    }),
    personalDetails: builder.mutation({
      query: (post) => ({
        url: personalDetails,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response ?? response : response;
      },
    }),
  }),
});

export const {
  useGetAllUserWalletQuery,
  useTransferAccountMutation,
  useGetPaymentTokenQuery,
  usePaymentRequestMutation,
  usePaymentCountryQuery,
  usePaymentMethodsMutation,
  useWithdrawalRequestMutation,
  useAccountDetailsMutation,
  useGetAccountDetailsQuery,
  usePersonalDetailsMutation,
} = AccountApi;
