import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate, NavLink, useParams } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import toast from "react-hot-toast";
import CheckboxList from "./CheckBoxes";
import { useDropdown } from "./useDropdown";
import { useTranslation } from "react-i18next";
import ChangeLang from "./ChangeLang";
import { useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";

const ExchangeNavBar = ({
  resetLayout,
  changeChartTheme,
  getLayoutSettings,
  layoutChanged,
}) => {
  const { t } = useTranslation();
  const userSegment = useSelector((state) => state.user);
  const token = localStorage.getItem("jwtToken");
  const pathname = window.location.pathname;
  const firstPath = pathname.split("/")[1];
  const { symbol } = useParams();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const themeDropdown = useDropdown();
  const menuDropdown = useDropdown();
  const layoutDropdown = useDropdown();
  const [selectedTheam, setSelectedTheam] = useState("");
  const [checkBoxesReal, setCheckBoxes] = useState([]);

  const [show, setShow] = useState(false);

  const checkboxes = [
    { label: t("Chart"), checked: true, setting: "chart" },
    { label: t("Market Depth"), checked: true, setting: "marketdepth" },
    { label: t("Orders"), checked: true, setting: "orderdetails" },
    { label: t("Buy"), checked: true, setting: "buy" },
  ];

  const checkCrypto = [
    { label: t("Chart"), checked: true, setting: "chart" },
    { label: t("Market Depth"), checked: true, setting: "marketdepth" },
    { label: t("Pairs List"), checked: true, setting: "pairslist" },
    { label: t("Trades"), checked: true, setting: "trades" },
    { label: t("Orders"), checked: true, setting: "orderdetails" },
    { label: t("Buy"), checked: true, setting: "buy" },
  ];

  const getPathSegment = () => window.location.pathname.split("/")[1];

  const getDisabledCheckBoxesKey = (pathSegment) => {
    if (pathSegment === "forex" || pathSegment === "forex-equity") return "frl";
    if (pathSegment === "crypto" || pathSegment === "crypto-futures")
      return "rl";
    return null;
  };

  const getInitialCheckboxes = (pathSegment) => {
    if (pathSegment === "forex" || pathSegment === "forex-equity")
      return checkboxes;
    if (pathSegment === "crypto" || pathSegment === "crypto-futures")
      return checkCrypto;
    return [];
  };

  const updateCheckboxes = useCallback((checkboxes, settingsToUncheck) => {
    return checkboxes.map((checkbox) => {
      if (settingsToUncheck.includes(checkbox.setting)) {
        return { ...checkbox, checked: false };
      }
      return checkbox;
    });
  }, []);

  useEffect(() => {
    const updateCheckboxesState = () => {
      const pathSegment = getPathSegment();
      const disabledCheckBoxesKey = getDisabledCheckBoxesKey(pathSegment);

      if (!disabledCheckBoxesKey) return;

      const disabledCheckBoxes = localStorage.getItem(disabledCheckBoxesKey);
      if (!disabledCheckBoxes || disabledCheckBoxes.length === 0) {
        setCheckBoxes(getInitialCheckboxes(pathSegment));
      } else {
        const updatedCheckboxes = updateCheckboxes(
          pathSegment === "forex" || pathSegment === "forex-equity"
            ? checkboxes
            : checkCrypto,
          disabledCheckBoxes
        );
        setCheckBoxes(updatedCheckboxes);
      }
    };

    const timer = setTimeout(updateCheckboxesState, 1000);
    return () => clearTimeout(timer);
  }, [layoutChanged, updateCheckboxes, setCheckBoxes]);

  useEffect(() => {
    const theme = localStorage.getItem("theme");
    if (theme) {
      document.body.className = theme;
      setSelectedTheam(theme);
      if (changeChartTheme) {
        changeChartTheme(theme);
      }
    }
  }, []);

  const setTheme = (e, themeName) => {
    e.preventDefault();
    if (themeName === "defaultTheme") {
      document.body.className = "";
    } else {
      document.body.className = themeName;
    }
    setSelectedTheam(themeName);
    localStorage.setItem("theme", themeName);
    if (changeChartTheme) {
      changeChartTheme(themeName);
    }
  };

  const logoutPage = () => {
    toast.success(t("Logout successfully"));
    localStorage.clear();
    logout();
    setTimeout(() => {
      window.location.href = "/login";
    }, 1000);
  };
  const handleShow = () => setShow(true);

  return (
    <div className="header_main bg2">
      <div className="container-fluid">
        <div className="manu d-flex align-items-center">
          <Link to="/">
            <img
              className="head_logo logo_lite"
              src="/img/logo.png"
              alt="Head Logo"
            />
            <img
              src="/img/logo-black.png"
              alt="Head Logo"
              className="head_logo logo_dark"
            />
          </Link>
          <span className="manu_btn" onClick={handleShow}>
            <i className="bi bi-list"></i>
          </span>

          {token ? (
            <div className="exchange_route ml-4 d-flex mr-auto">
              {userSegment.isCryptoEnabled === "yes" && (
                <NavLink
                  exact={true}
                  to="/crypto/BTC/USDT"
                  activeClassName="active"
                >
                  {t("Crypto")}
                </NavLink>
              )}
              {userSegment.isCryptoFutureEnabled === "yes" && (
                <NavLink
                  exact={true}
                  to="/crypto-futures/BTC/USDT"
                  activeClassName="active"
                >
                  {t("Crypto")} {t("Futures")}
                </NavLink>
              )}
              {userSegment.isFxEnabled === "yes" && (
                <NavLink
                  exact={true}
                  to={`/forex/${
                    firstPath === "forex"
                      ? symbol
                        ? symbol
                        : "EURUSD"
                      : "EURUSD"
                  }`}
                  activeClassName="active"
                >
                  {t("FX")}
                </NavLink>
              )}
              {userSegment.isEquityEnabled === "yes" && (
                <NavLink
                  exact={true}
                  to={`/forex-equity/${
                    firstPath === "forex-equity"
                      ? symbol
                        ? symbol
                        : "GOOGL"
                      : "GOOGL"
                  }`}
                  activeClassName="active"
                >
                  {t("Equity")}
                </NavLink>
              )}
            </div>
          ) : (
            <div className="exchange_route ml-4 d-flex mr-auto">
              <NavLink
                exact={true}
                to="/crypto/BTC/USDT"
                activeClassName="active"
              >
                {t("Crypto")}
              </NavLink>

              <NavLink
                exact={true}
                to={`/forex/${
                  firstPath === "forex"
                    ? symbol
                      ? symbol
                      : "EURUSD"
                    : "EURUSD"
                }`}
                activeClassName="active"
              >
                {t("FX")}
              </NavLink>

              <NavLink
                exact={true}
                to={`/forex-equity/${
                  firstPath === "forex-equity"
                    ? symbol
                      ? symbol
                      : "GOOGL"
                    : "GOOGL"
                }`}
                activeClassName="active"
              >
                {t("Equity")}
              </NavLink>
            </div>
          )}

          {!token && (
            <>
              {/* <ChangeLang /> */}
              <Link className="btn mr-3" to={"/login"}>
                Login
              </Link>
            </>
          )}
          {/* {token && (
            <>
              <div className="row current_unds_item">
                <div className="mr-3">
                  <p className=" mb-0">
                    {t("Total")}: $
                    {isNaN(totalBalance)
                      ? "0.00"
                      : formatDecimal(totalBalance, 2)}
                  </p>
                </div>
                <div className="mr-3">
                  <p className="mb-0">
                    {t("Used")}: $
                    {isNaN(usedBalance)
                      ? "0.00"
                      : formatDecimal(usedBalance, 2)}
                  </p>
                </div>
                <div className="mr-5">
                  <p className="mb-0">
                    {t("Free")}: $
                    {isNaN(freeBalance)
                      ? "0.00"
                      : formatDecimal(freeBalance, 2)}
                  </p>
                </div>
              </div>
            </>
          )} */}
          <ChangeLang />

          {/* <GoogleTranslator /> */}
          {token && (
            <div className="dropdown user_dropdown  mr-3">
              <span
                type="button"
                className=" dropdown-toggle"
                data-toggle="dropdown"
                onClick={() => layoutDropdown.setIsOpen(!layoutDropdown.isOpen)}
              >
                <i className="bi bi-gear"></i>
              </span>
              <div
                className={
                  layoutDropdown.isOpen
                    ? "setting_box1 active "
                    : "setting_box1"
                }
              >
                <div className="setting_box2 bg2" ref={layoutDropdown.ref}>
                  <div className="d-flex justify-content-between">
                    <h6 className="text-left ">{t("Layout Settings")}</h6>
                    <div className="text-right">
                      <i
                        class="bi bi-x-lg clode_btn  cursor-pointer"
                        onClick={() =>
                          layoutDropdown.setIsOpen(!layoutDropdown.isOpen)
                        }
                      ></i>
                    </div>
                  </div>
                  {checkBoxesReal.length > 0 && (
                    <CheckboxList
                      checkboxes={checkBoxesReal}
                      getLayoutSettings={getLayoutSettings}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
          {token && (
            <div className="dropdown user_dropdown  mr-3 mo_none">
              <span
                type="button"
                className="dropdown-toggle color-toggle"
                data-toggle="dropdown"
                onClick={() => themeDropdown.setIsOpen(!themeDropdown.isOpen)}
              >
                {selectedTheam === "lightTheme" ? (
                  <i className="bi bi-brightness-high"></i>
                ) : selectedTheam === "defaultTheme" ? (
                  <i className="bi bi-moon-stars"></i>
                ) : (
                  <i className="bi bi-moon"></i>
                )}{" "}
              </span>
              <div
                ref={themeDropdown.ref}
                className={
                  themeDropdown.isOpen
                    ? "dropdown-menu2 active"
                    : "dropdown-menu2"
                }
              >
                <a
                  className="a_flex"
                  href="#!"
                  onClick={(e) => setTheme(e, "lightTheme")}
                >
                  <i className="bi bi-brightness-high mr-2"></i> {t("Light")}
                </a>
                <a
                  className="a_flex"
                  href="#!"
                  onClick={(e) => setTheme(e, "defaultTheme")}
                >
                  <i className="bi bi-moon-stars mr-1"></i> {t("Dim")}
                </a>
                <a
                  className="a_flex"
                  href="#!"
                  onClick={(e) => setTheme(e, "darkTheme")}
                >
                  <i className="bi bi-moon mr-1"></i> {t("Dark")}
                </a>
                {resetLayout && (
                  <>
                    <hr />
                    <a
                      className="a_flex"
                      href="#!"
                      onClick={(e) => {
                        resetLayout(window.location.pathname.split("/")[1]);
                      }}
                    >
                      <i className="bi bi-x-diamond mr-1"></i>{" "}
                      {t("Reset Layout")}
                    </a>
                  </>
                )}
              </div>
            </div>
          )}
          {token && (
            <div className="dropdown user_dropdown mo_none">
              <span
                type="button"
                className=" dropdown-toggle"
                data-toggle="dropdown"
                onClick={() => menuDropdown.setIsOpen(!menuDropdown.isOpen)}
              >
                <i className="bi bi-person"></i>{" "}
              </span>
              <div
                ref={menuDropdown.ref}
                className={
                  menuDropdown.isOpen
                    ? "dropdown-menu2 active"
                    : "dropdown-menu2"
                }
              >
                <Link className="a_flex" to="/dashboard">
                  <i className="bi bi-grid mr-2"></i>
                  {t("Dashboard")}
                </Link>
                <Link className="a_flex" to={""} onClick={logoutPage}>
                  <i className="bi bi-box-arrow-right mr-2"></i>
                  {t("Logout")}
                </Link>
              </div>
            </div>
          )}
        </div>
        {/* React-Bootstrap Modal (Styled as Sidebar) */}
        <Modal
          show={show}
          onHide={() => setShow(false)}
          className="custom-modal custom-modal-nav"
        >
          <Modal.Body>
            <span
              className="close-btn close_btn"
              onClick={() => setShow(false)}
            >
              <i className="bi bi-x-lg"></i>
            </span>

            <Link to="/" className="mb-3 d-block">
              <img
                className="head_logo logo_lite"
                src="/img/logo.png"
                alt="Head Logo"
              />
              <img
                src="/img/logo-black.png"
                alt="Head Logo"
                className="head_logo logo_dark"
              />
            </Link>
            <div className="modal-header-buttons d-flex">
              {!token && (
                <>
                  <Button
                    variant="dark"
                    className="btn-login w100 mr-2 "
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    Log In
                  </Button>
                  <Button
                    variant="warning"
                    className="ml-2 btn-signup w100"
                    onClick={() => {
                      navigate("/register");
                    }}
                  >
                    Sign Up
                  </Button>
                </>
              )}
            </div>
            {/* Menu Items */}
            <ul className="modal-menu">
              <li>
                <i className="bi bi-grid mr-2"></i>
                <NavLink exact={true} to="/dashboard" activeClassName="active">
                  {t("Dashboard")}
                </NavLink>
              </li>
              <li>
                <i className="bi bi-currency-bitcoin mr-2"></i>{" "}
                <NavLink
                  exact={true}
                  to="/crypto/BTC/USDT"
                  activeClassName="active"
                >
                  {t("Crypto")}
                </NavLink>
              </li>
              <li>
                <i className="bi bi-bar-chart mr-2"></i>{" "}
                <NavLink
                  exact={true}
                  to={`/forex/${
                    firstPath === "forex"
                      ? symbol
                        ? symbol
                        : "EURUSD"
                      : "EURUSD"
                  }`}
                  activeClassName="active"
                >
                  {t("FX")}
                </NavLink>
              </li>
              <li>
                <i className="bi bi-graph-up mr-2"></i>{" "}
                <NavLink
                  exact={true}
                  to={`/forex-equity/${
                    firstPath === "forex-equity"
                      ? symbol
                        ? symbol
                        : "GOOGL"
                      : "GOOGL"
                  }`}
                  activeClassName="active"
                >
                  {t("Equity")}
                </NavLink>
              </li>
              {/* <li>
                <i className="bi bi-wallet2 mr-2"></i> Futures
              </li>
              <li>
                <i className="bi bi-trophy mr-2"></i> Earn
              </li>
              <li>
                <i className="bi bi-collection mr-2"></i> Square
              </li> */}

              <li className="align-items-center d-flex">
                <span
                  type="button"
                  className="dropdown-toggle color-toggle mr-2"
                  data-toggle="dropdown"
                  onClick={() => themeDropdown.setIsOpen(!themeDropdown.isOpen)}
                >
                  {selectedTheam === "lightTheme" ? (
                    <i className="bi bi-brightness-high"></i>
                  ) : selectedTheam === "defaultTheme" ? (
                    <i className="bi bi-moon-stars"></i>
                  ) : (
                    <i className="bi bi-moon"></i>
                  )}{" "}
                </span>
                Thame
                <div className="ml-auto mo_color">
                  <a
                    className={
                      selectedTheam === "lightTheme"
                        ? "a_flex active"
                        : "a_flex"
                    }
                    href="#!"
                    onClick={(e) => setTheme(e, "lightTheme")}
                  >
                    <i className="bi bi-brightness-high mr-1"></i> {t("Light")}
                  </a>
                  <a
                    className={
                      selectedTheam === "defaultTheme"
                        ? "a_flex active"
                        : "a_flex"
                    }
                    href="#!"
                    onClick={(e) => setTheme(e, "defaultTheme")}
                  >
                    <i className="bi bi-moon-stars mr-1"></i> {t("Dim")}
                  </a>
                  <a
                    className={
                      selectedTheam === "darkTheme" ? "a_flex active" : "a_flex"
                    }
                    href="#!"
                    onClick={(e) => setTheme(e, "darkTheme")}
                  >
                    <i className="bi bi-moon mr-1"></i> {t("Dark")}
                  </a>
                </div>
              </li>
              {resetLayout && (
                <li>
                  <a
                    className="a_flex"
                    href="#!"
                    onClick={(e) => {
                      resetLayout(window.location.pathname.split("/")[1]);
                    }}
                  >
                    <i className="bi bi-x-diamond mr-1"></i> {t("Reset Layout")}
                  </a>
                </li>
              )}
              <li>
                <Link className="a_flex" to={""} onClick={logoutPage}>
                  <i className="bi bi-box-arrow-right mr-2"></i>
                  {t("Logout")}
                </Link>
              </li>
            </ul>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default ExchangeNavBar;
