import React from "react";
import { useTranslation } from "react-i18next";
import { useGetMasterSubscribersListQuery } from "../../redux/userApi";
import { useEffect } from "react";

function Subscribers() {
  const { t } = useTranslation();
  const { data: subsList, isLoading: subsLoading, refetch } = useGetMasterSubscribersListQuery();

  useEffect(()=>{refetch()},[])

  const showSubsData = () => {
    return subsList?.map((sub, index) => (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{sub.user_name}</td>
        <td>{sub.email}</td>
        <td>{sub.allocated_balance}</td>
        <td>{sub.floating_profit}</td>
        <td>{sub.leverage}</td>
        <td>{sub.status}</td>
      </tr>
    ));
  };

  return (
    <div className="p-2">
      <div className="table-responsive trade_scrool_table">
        <table className="table table-striped mb-0">
          <thead>
            <tr>
              <th>{t("Sr. No.")}</th>
              <th>{t("Username")}</th>
              <th>{t("Email")}</th>
              <th>{t("Allocated Balance")}</th>
              <th>{t("Floating Profit")}</th>
              <th>{t("Leverage")}</th>
              <th>{t("Status")}</th>
            </tr>
          </thead>
          <tbody>
            {subsLoading ? (
              <tr>
                <td colSpan="7" className="text-center">{t("Loading...")}</td>
              </tr>
            ) : (
              showSubsData()
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Subscribers;
