import React, { useEffect, useRef, useState, memo } from "react";
import { useParams } from "react-router-dom";

function TradingViewWidget({ theme = "dark", getLoading }) {
  const container = useRef(null);
  const [loading, setLoading] = useState(true);
  const { firstCoin, secondCoin } = useParams();

  useEffect(() => {
    initChart();
  }, [firstCoin, secondCoin, theme]);

  const initChart = () => {
    setLoading(true); // show spinner/skeleton

    const pair = firstCoin + secondCoin;
    const currentContainer = container.current;
    // Clear any existing chart
    currentContainer.innerHTML = "";

    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
    script.type = "text/javascript";
    script.async = true;
    script.crossOrigin = "anonymous";

    // This is the config object in the script's innerHTML
    script.innerHTML = JSON.stringify({
      autosize: true,
      timezone: "Asia/Kolkata",
      symbol: pair,
      interval: "D",
      theme: theme,
      style: "8",
      locale: "en",
      enable_publishing: false,
      withdateranges: true,
      hide_side_toolbar: false,
      allow_symbol_change: true,
      calendar: false,
      support_host: "https://www.tradingview.com",
    });

    // When the script has loaded (which means it has created the iframe),
    // you can guess the chart is done after a short delay or attempt
    // to track the <iframe> load event.
    script.onload = () => {
      // There's not a perfect callback from TradingView's script itself,
      // so using a small setTimeout to hide the spinner is a decent hack.
      setTimeout(() => {
        setLoading(false);
        getLoading(false);
      }, 1500); // adjust as needed
    };

    currentContainer.appendChild(script);
  };

  return (
    <div style={{ position: "relative", height: "100%" }}>
      {/* Chart Container */}
      <div ref={container} id="tv_chart_container" />
      {/* Simple Spinner Overlay */}
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0,0,0,0.4)",
          }}
        >
          <div style={{ color: "#fff" }}>Loading chart...</div>
        </div>
      )}
    </div>
  );
}

export default memo(TradingViewWidget);
