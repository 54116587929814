import React, { useEffect, useState } from "react";
import Footer from "../widgets/Footer";
import Navbar from "../widgets/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import moment from "moment";
import {
  useGetMasterProfileQuery,
  useGetMastersMetricsQuery,
  useGetMasterCloseTradesQuery,
  useGetMastersOpenTradesQuery,
  useGetMasterBalanceHistoryQuery,
  useGetCopierSubsQuery,
} from "../../redux/userApi";
import { imageUrl } from "../constant/BaseUrl";
import SubScriptionSetup from "./SubScriptionSetup";
import { formatDecimal } from "../../util/decimalFormatter";
import { useSelector } from "react-redux";
import ProfitLossBar from "../partials/ProfitLossBar";
import AreaChart from "../partials/AreaChart";
import AssetPreferencesChart from "../partials/AssetPreferencesChart";
import TooltipShow from "../partials/ToolTip";

const ToolTipCover = ({ id, content }) => (
  <TooltipShow id={id} content={content}>
    <span data-tooltip-id={id}>
      <i
        class="bi bi-info-circle-fill ml-2"
        style={{
          fontSize: "12px",
          // lineHeight: "20px",
        }}
      ></i>
    </span>
  </TooltipShow>
);

const MasterProfile = () => {
  const { t } = useTranslation();
  const { userData } = useSelector((state) => state.user);
  const { masterUserId } = useParams();
  const navigate = useNavigate();
  const [chartData, setChartData] = useState([]);
  const [dChartData, setDChartData] = useState({ symbols: [], values: [] });
  const [assetPreferencesChart, setAssetPreferenceData] = useState({
    symbols: [],
    values: [],
  });
  const [timeFrame, setTimeFrame] = useState("1M");
  const [activeTab, setActiveTab] = useState("Closed orders");
  const [isModelShow, setIsModelShow] = useState();
  const { data, isLoading } = useGetMasterProfileQuery({ masterUserId });
  const { data: maters, refetch } = useGetMastersMetricsQuery({
    masterUserId,
    period: timeFrame,
  });
  const { data: closeOrders = [], isLoading: closing } =
    useGetMasterCloseTradesQuery({
      masterUserId,
    });
  const { data: openOrders = [], isLoading: opening } =
    useGetMastersOpenTradesQuery({
      masterUserId,
    });
  const { data: balanceHistory = [], isLoading: balancing } =
    useGetMasterBalanceHistoryQuery({
      userId: masterUserId,
    });

  const { data: copierSubs, isLoading: checkingCopierSubs } =
    useGetCopierSubsQuery({ masterUserId }, { skip: !userData });

  useEffect(() => {
    const data = closeOrders
      .map((item) => {
        return {
          date: moment(item.updated_at).format("lll"),
          pnl: item.pnl,
          symbol: item.symbol,
        };
      })
      .sort((a, b) => a.date.localeCompare(b.date));

    const groupedPnl = {};
    const tradeCount = {};
    let totalPnl = 0;

    data.forEach((entry) => {
      // Group by PnL
      if (!groupedPnl[entry.symbol]) groupedPnl[entry.symbol] = 0;
      groupedPnl[entry.symbol] += entry.pnl;
      totalPnl += entry.pnl;

      // Group by trade count
      if (!tradeCount[entry.symbol]) tradeCount[entry.symbol] = 0;
      tradeCount[entry.symbol] += 1;
    });

    // For Doughnut chart - PnL percentage distribution
    const symbols = [];
    const pnlPercentages = [];

    for (const [symbol, pnl] of Object.entries(groupedPnl)) {
      symbols.push(symbol);
      pnlPercentages.push(((pnl / totalPnl) * 100).toFixed(2));
    }

    // For Doughnut chart - Asset preference based on number of trades
    const assetSymbols = [];
    const assetPercentages = [];
    const totalTrades = Object.values(tradeCount).reduce(
      (sum, count) => sum + count,
      0
    );

    for (const [symbol, count] of Object.entries(tradeCount)) {
      assetSymbols.push(symbol);
      assetPercentages.push(((count / totalTrades) * 100).toFixed(2));
    }

    // Set PnL-based chart
    setDChartData({
      symbols: symbols,
      values: pnlPercentages,
    });

    // Set Asset Preference chart
    setAssetPreferenceData({
      symbols: assetSymbols,
      values: assetPercentages,
    });

    setChartData(data);
    refetch();
  }, [timeFrame, closeOrders]);

  useEffect(() => {
    const { is_master_trader } = userData || "0";

    if (is_master_trader === "1") {
      navigate("/dashboard");
    }
  }, [userData, navigate]);

  if (isLoading && closing && balancing && opening) return;

  const closeSubscription = () => {
    setIsModelShow(false);
  };

  if (!data) return;

  return (
    <div>
      <Navbar />
      <section className="p70 pt-md-5 pb-md-5">
        <div className="container ">
          <h3 className="mb-3">Dynamic Tech</h3>
          <div className="p-md-4 p-3 bg2 br20 mb-4">
            <div className="row">
              <div className="col-lg-3 col-md-4">
                <div className="d-flex mb-3">
                  <div className="mpa mpa2">
                    <img
                      className="profile_avatar-flag"
                      alt="profile avatar"
                      src={
                        data.profileImage
                          ? imageUrl +
                            "/static/masterProfileImages/" +
                            data.profileImage
                          : "/img/Default_pfp.png"
                      }
                    />
                  </div>
                  <div className="pl-3">
                    <h5 className="mb-1">{data.nickName}</h5>
                    <p className="light_color">
                      <i className="bi bi-star mr-2 color1"></i>
                      {data.level}
                    </p>
                  </div>
                </div>
                <div className="text-center">
                  <button
                    onClick={() => setIsModelShow(true)}
                    className="btn w100 disabled"
                    disabled={checkingCopierSubs || copierSubs}
                    style={{ cursor: copierSubs ? "not-allowed" : "pointer" }}
                  >
                    {" "}
                    {/* {data.masterUserId === Number(masterUserId)
                      ? "Subscribed"
                      : "Set up copying"} */}
                    {copierSubs ? "Subscribed" : "Set up copying"}
                  </button>
                  <p className="mt-2">
                    Minimum investment ${data.minimumInvestment}
                  </p>
                </div>
              </div>
              <div className="col-md-8 ml-auto">
                <div className="card-content__performance mb-4 ">
                  {/* <div className="ccpi2">
                    <h6 className="light_color">Risk score</h6>
                    <div className="h_btn ml-auto">1 risk</div>
                  </div> */}
                  <div className="ccpi2">
                    <h6 className="light_color">
                      Equity⁠{" "}
                      <ToolTipCover
                        id="floatingProfitTooltip4"
                        content="Equity is the sum of your account balance and your account's unrealised profit or loss."
                      />
                    </h6>
                    <div className="ccpv">${data.equity}</div>
                  </div>
                  <div className="ccpi2">
                    <h6 className="light_color">Commission⁠</h6>
                    <div className="ccpv">
                      {data.commissionRate > 0 ? data.commissionRate : "FREE"}%
                    </div>
                  </div>
                  <div className="ccpi2">
                    <h6 className="light_color">Segment</h6>
                    <div className="ccpv">
                      {[
                        data.is_crypto === "1" && "Crypto",
                        data.is_forex === "1" && "Forex",
                        data.is_equity === "1" && "Equity",
                      ]
                        .filter(Boolean)
                        .join(", ")}{" "}
                    </div>
                  </div>
                </div>

                {data.description && (
                  <>
                    <h6 className="light_color">About Master </h6>
                    <p>{data.description}</p>
                  </>
                )}
                {data.links && (
                  <p>
                    Join chat:{" "}
                    <a className="color1" href={data.links}>
                      {data.links}
                    </a>{" "}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="p-md-4 p-3 bg2 br20 mb-4">
            <h5 className="mb-3 mb-md-4">Performance</h5>
            <div className="row">
              <div className="col-md-4">
                <div className="d-flex mb-3 wmm_btn">
                  <button
                    onClick={() => setTimeFrame("2W")}
                    className={`cursor-pointer ${
                      timeFrame === "2W" && "active"
                    }`}
                  >
                    2W
                  </button>
                  <button
                    onClick={() => setTimeFrame("1M")}
                    className={`cursor-pointer ${
                      timeFrame === "1M" && "active"
                    }`}
                  >
                    1M
                  </button>
                  <button
                    onClick={() => setTimeFrame("3M")}
                    className={`cursor-pointer ${
                      timeFrame === "3M" && "active"
                    }`}
                  >
                    3M
                  </button>
                  <button
                    onClick={() => setTimeFrame("6M")}
                    className={`cursor-pointer ${
                      timeFrame === "6M" && "active"
                    }`}
                  >
                    6M
                  </button>
                  <button
                    onClick={() => setTimeFrame("all")}
                    className={`cursor-pointer ${
                      timeFrame === "all" && "active"
                    }`}
                  >
                    All
                  </button>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-flex     justify-content-between;">
                  <div className="ccpi2">
                    <h6 className="light_color">Gain⁠</h6>
                    <div className="ccpv ml-auto">{data.gains}% </div>
                  </div>
                  <div className="ccpi2 ml-4">
                    <h6 className="light_color">Copiers⁠</h6>
                    <div className="ccpv ml-auto">
                      {maters?.total_active_copiers}{" "}
                      <i class="bi bi-arrow-up"></i> {maters?.new_copiers}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 ml-auto">
                <ProfitLossBar
                  pnl={data.pnl}
                  totalDeposit={data.totalDeposit}
                  balance={data.balance}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-7">
              <div className="p-md-4 p-3 bg2 br20 mb-4">
                <h5 className="mb-3">Account details </h5>
                <div className="row">
                  <div className="mb-4 col-6">
                    <h6 className="light_color">
                      Floating Profit{" "}
                      <ToolTipCover
                        id="floatingProfitTooltip"
                        content="Floating profit represents the total combined profit from all active positions in the Master Trader's account."
                      />
                      ⁠
                    </h6>
                    <div className="ccpv">${data.floatingProfit} </div>
                  </div>
                  <div className="mb-4 col-6">
                    <h6 className="light_color">
                      Balance⁠⁠{" "}
                      <ToolTipCover
                        id="floatingProfitTooltip5"
                        content="Balance refers to the Master Trader's available funds, excluding amounts tied to open orders."
                      />
                    </h6>
                    <div className="ccpv">${data.balance}</div>
                  </div>
                  <div className="mb-4 col-6">
                    <h6 className="light_color">Master Trader's bonus⁠ </h6>
                    <div className="ccpv">${data.masterBonus}</div>
                  </div>
                  <div className="mb-4 col-6">
                    <h6 className="light_color">
                      Leverage{" "}
                      <ToolTipCover
                        id="floatingProfitTooltip3"
                        content="Leverage is a virtual credit that reduces the margin required to open an order. Higher leverage allows the Master Trader to use fewer personal funds for opening orders, but it also increases the associated risks."
                      />
                      ⁠
                    </h6>
                    <div className="ccpv">1:{data.leverage} </div>
                  </div>
                </div>
              </div>
            </div>
            {/* {closeOrders && closeOrders.length > 0 && ( */}
            <div className="col-md-5">
              <div className="p-md-4 p-3 bg2 br20 mb-4">
                <h5 className="mb-3">
                  Asset Preferences{" "}
                  <ToolTipCover
                    id="floatingProfitTooltip2"
                    content="Asset preference shows the breakdown of the most traded Assets of the master trader."
                  />
                </h5>
                <AssetPreferencesChart allData={assetPreferencesChart} />
              </div>
            </div>
            {/* )} */}
            {chartData.length > 0 && (
              <div className="col-md-12">
                <div className="p-md-4 p-3 bg2 br20 mb-4">
                  <h5 className="mb-3 mb-md-4">Activity</h5>
                  <AreaChart allData={chartData} />
                </div>
              </div>
            )}
            <div className="col-md-12">
              <div className="p-md-4 p-3 bg2 br20 mb-4">
                <h5 className="mb-3 mb-md-4">History</h5>
                <div className="position_tab d-md-flex mb-3 h4 fs18 ttu">
                  <a
                    href="#!"
                    className={activeTab === "Closed orders" ? "active" : ""}
                    onClick={() => setActiveTab("Closed orders")}
                  >
                    Closed orders
                  </a>
                  <a
                    href="#!"
                    className={activeTab === "Open orders" ? "active" : ""}
                    onClick={() => setActiveTab("Open orders")}
                  >
                    Open orders ({openOrders && openOrders.length})
                  </a>
                  <a
                    href="#!"
                    className={
                      activeTab === "Balance operations" ? "active" : ""
                    }
                    onClick={() => setActiveTab("Balance operations")}
                  >
                    Balance operations
                  </a>
                </div>

                {activeTab === "Closed orders" && (
                  <div className="table-responsive">
                    <table className="table close-orders">
                      <thead>
                        <tr className="ttu">
                          <th>Symbol</th>
                          <th>Quantity</th>
                          <th>Segment</th>
                          <th>Open Time</th>
                          <th className="text-right">Close Time</th>
                          <th className="text-right">Profit</th>
                        </tr>
                      </thead>
                      <tbody>
                        {closeOrders &&
                          closeOrders?.map((item) => (
                            <>
                              <tr>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <span className="h_btn mr-3">
                                      {item.order_side === "BUY" ? (
                                        <i
                                          className={`"bi bi-chevron-up "`}
                                        ></i>
                                      ) : (
                                        <i
                                          className={`"bi bi-chevron-down "`}
                                        ></i>
                                      )}
                                    </span>
                                    <span>{item.symbol}</span>
                                  </div>
                                </td>
                                <td>
                                  <span className="mr-3">{item.quantity}</span>
                                </td>
                                <td>{item.segment}</td>
                                <td>
                                  {" "}
                                  {moment(item.created_at).format("lll")}
                                </td>
                                <td className="text-right">
                                  {" "}
                                  {moment(item.updated_at).format("lll")}
                                </td>
                                <td className="text-right">
                                  <span
                                    className={`color1 ${
                                      item.pnl < 0 ? "text-danger" : ""
                                    }`}
                                  >
                                    ${item.pnl}
                                  </span>
                                </td>
                              </tr>
                            </>
                          ))}
                      </tbody>
                    </table>
                  </div>
                )}

                {activeTab === "Open orders" && (
                  <div className="table-responsive">
                    <table className="table close-orders">
                      <thead>
                        <tr className="ttu">
                          <th>Symbol</th>
                          <th>Quantity</th>
                          <th>Segment</th>
                          <th>Open Time</th>
                          {/* <th className="text-right">Close Time</th> */}
                          <th className="text-right">Profit</th>
                        </tr>
                      </thead>
                      <tbody>
                        {openOrders &&
                          openOrders?.map((item) => (
                            <>
                              <tr>
                                <td>
                                  <div className="d-flex align-items-center ttu">
                                    <span className="h_btn mr-3">
                                      {item.order_side === "BUY" ? (
                                        <i
                                          className={`"bi bi-chevron-up "`}
                                        ></i>
                                      ) : (
                                        <i
                                          className={`"bi bi-chevron-down "`}
                                        ></i>
                                      )}
                                    </span>
                                    <span className="text-capitalize ttu">
                                      {item.symbol}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <span className="mr-3">{item.quantity}</span>
                                </td>
                                <td>{item.segment}</td>
                                <td> {moment(item.open_time).format("lll")}</td>
                                {/* <td className="text-right">
                                  {" "}
                                  {moment(item.updated_at).format("lll")}
                                </td> */}
                                <td className="text-right">
                                  <span
                                    className={`color1 ${
                                      item.total_floating_profit < 0
                                        ? "text-danger"
                                        : ""
                                    }`}
                                  >
                                    ${item.total_floating_profit}
                                  </span>
                                </td>
                              </tr>
                            </>
                          ))}
                      </tbody>
                    </table>
                  </div>
                )}

                {activeTab === "Balance operations" && (
                  <div className="table-responsive">
                    <h5 class="nav-item mb-2">Balance History</h5>

                    <table className="table">
                      <thead>
                        <tr className="ttu">
                          {/* <th>S. No.</th> */}
                          <th>Type</th>
                          <th>Date</th>
                          {/* <th>Order ID</th> */}
                          <th className="text-right">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {balanceHistory &&
                          balanceHistory.map((item) => (
                            <tr>
                              {/* <td>{i + 1}</td> */}
                              <td className="ttu">{item.operation_type}</td>
                              <td>{moment(item.created).format("lll")}</td>

                              {/* <td>#{item.id}</td> */}
                              <td
                                className={`text-right ${
                                  item.amount < 0
                                    ? "text-danger"
                                    : "text-success"
                                }`}
                              >
                                ${formatDecimal(item.amount)}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={isModelShow}
          onHide={closeSubscription}
          className="account-popup-outer"
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title className="mb-0">
              {" "}
              {t("Subscription summary")}
            </Modal.Title>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={closeSubscription}
            >
              <i className="bi bi-x-lg"></i>
            </button>
          </Modal.Header>
          <Modal.Body>
            <SubScriptionSetup
              profileImage={data.profileImage}
              masterName={data.nickName}
              commission={data.commissionRate}
              requiredAmount={data.minimumInvestment}
              masterUserId={data.masterUserId}
            />
          </Modal.Body>
        </Modal>
      </section>
      <Footer />
    </div>
  );
};

export default MasterProfile;
