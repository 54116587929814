import React from 'react'
import { useTranslation } from "react-i18next";

function CommissionHistory() {
    const { t } = useTranslation();
    return (
      <div className="p-2">
        <div className="table-responsive trade_scrool_table">
          <table className="table table-striped mb-0 ">
            <thead>
              <tr>
                <th>{t("Sr. No.")}</th>
                <th>{t("Trade Type")}</th>
                <th>{t("Pair")} </th>
                <th>{t("Price")}</th>
                <th>{t("Quantity")}</th>
                <th>{t("Trade Fee")}</th>
                <th>{t("Total")}</th>
                <th>{t("Order Method")}</th>
                <th>{t("Created")}</th>
              </tr>
            </thead>
            {/* <tbody>{showTableHtmlCrypto()}</tbody> */}
            <tbody>{}</tbody>
          </table>
        </div>
      </div>
    );
}

export default CommissionHistory