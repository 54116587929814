import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Navbar from "../widgets/Navbar";
import Sidebar from "../widgets/Sidebar";
import Footer from "../widgets/Footer";
import MasterProfileSetup from "../pages/MasterProfileSetup";
import Subscribers from "./Subscribers";
import CommissionHistory from "./CommissionHistory";

function MasterTabs() {
  return (
    <div>
      <Navbar />
      <section className="p70 pt-md-5 pb-md-5">
        <div className="container">
          <div className="row">
            <Sidebar />
            <div className="col-xl-9 col-lg-8">
              {/* <div className="nav-tabs3  bg2 br10 mb-4"> */}
              <div className="p-3 bg2 br20 mb-4">
                <Tabs >
                  <TabList>
                    <Tab>Profile</Tab>
                    <Tab>Subscribers</Tab>
                    {/* <Tab>Commission History</Tab> */}
                  </TabList>

                  <TabPanel>
                    <MasterProfileSetup />
                  </TabPanel>

                  <TabPanel>
                    <Subscribers />
                  </TabPanel>

                  <TabPanel>
                    <CommissionHistory />
                  </TabPanel>
                </Tabs>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default MasterTabs;
